@import './constants';
// Bebas Neue font
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
  font-family: 'new-york-times';
  src: url("./engravers-old-english-bt.ttf");
}
@font-face {
  font-family: 'newspaper';
  src: url("./AbrilFatface-Regular.ttf");
}

body {
  //background-image: url("../images/vintage-grunge-paper-background.jpg");
  background-color: #F5F2E8;
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
  color: $black;
  font-family: newspaper,Calibri, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  
  &::after {
    content: '';
    transition: margin-left 200ms, background 200ms;
  }

  &.hamburger-open {
    margin-left: -$hamburger-menu-width;
    
    &::after {
      background: rgba(0, 0, 0, 0.25);
      content: '';
      height: 100vh;
      left: 0;
      margin-left: -$hamburger-menu-width;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 1000;
    }
  }
}

h1 {
  font-size: 2em;
  font-weight: normal;
  font-family: newspaper;
}

h2 {
  font-size: 3em;
  font-weight: normal;
  font-family: newspaper;
  color: #333333;
}

h3 {
  font-size: 2.5em;
  font-weight: normal;
  font-family: 'Bebas Neue';     
}

p {
  font-size: 1.25em;
}

button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
}

strong {
  font-weight: 600;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

.app {
  align-items: center;
  //background: linear-gradient(#fff8e8, #fff 700px);
  //backgrozund-image: url('../images/hex.svg');
  background-repeat: repeat;
  background-size: 50%;
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  width: 100vw;

  .logo {
    background-image: url('../images/scissor-logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 125%;
  }
}


.subtitle {
  font-size: 1.1em;
}

@media only screen and (max-width: 840px) {
  h1 {
    font-size: 3.3em;
  }

  h2 {
    font-size: 2.8em;
  }

  h3 {
    font-size: 1.5em;     
  }
  
  p {
    font-size: 1em;
  }
  
  .subtitle {
    font-size: 1em;
  }

  .box {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 570px) {
  body {
    transition: margin-left 200ms;
  }

  h1 {
    font-size: 2.4em;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.15em;
  }

  p,
  a,
  .subtitle {
    font-size: 0.78em;
  }

  .box {
    padding: 40px 20px;
  }
}
.bee {
  position: absolute;
  width: 60px;
  top: -30px;
  left: -30px;
  z-index: 1;
  transform-origin: center center;
}
#parallax-container {
  position: fixed;
  min-height: 100vh;
  width: 100%;
  z-index: -50;
}

.parallax {
  position: fixed;
  max-width: 100%;
  z-index: -50;
  will-change: transform;
}
