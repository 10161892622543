@import './constants.scss';

.navbar {
  position: fixed;
  top: 0;
  z-index: 10;

  .banner {
    align-items: center;
    background: none;
    display: flex;
    font-weight: 600;
    height: $banner-height;
    justify-content: center;
    position: relative;
    text-align: center;
    transition: background 200ms;
    width: 100vw;
    
    p {
      font-size: 13px !important;
    }

    &:hover {
      background: none;
    }
  }

  .navigation {
    .container {
      align-items: center;
      background-color: #F5F2E8;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      display: flex;
      height: $navbar-height;
      justify-content: space-between;
      left: 0;
      padding: 0 50px;
      transition: left 200ms;
      transition: margin-left 200ms;
      width: 100vw;

      .logo-and-bug {
        align-items: center;
        display: flex;

        .virtual-bug {
          background: rgba($red, 0.7);
          height: fit-content;
          margin-left: 30px;
          padding: 5px;
          transform: skew(-10deg);

          @media only screen and (max-width: 820px) {
            width: min-content;
          }

          @media only screen and (max-width: 680px) {
            display: none;
          }

          @media only screen and (max-width: 570px) {
            display: block;
            width: auto;
          }

          @media only screen and (max-width: 460px) {
            display: none;
          }
          
          p {
            color: white;
            font-weight: bold;
          }
        }
      }

      &.open {
        left: -$hamburger-menu-width;
      }

      .logo {
        background-image: url('../images/scissor-logo.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 125%;
        height: 50px;
        width: 50px;
      }

      a {
        color: inherit;
        height: 100%;
        text-decoration: none;
      }

      b {
        font-family: new-york-times, serif;
        font-size: 4em;
        float: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      #nav-items {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        padding-right: 130px;

        button {
          background: none;
          border: 0;
          color: inherit;
          font-family: 'Bebas Neue', 'Roboto';
          font-size: inherit;
          height: 100%;
          outline: none;
          text-decoration: none;
        }

        li {
          color: black;
          align-items: center;
          cursor: pointer;
          display: flex;
          flex-flow: column;
          font-size: 20px;
          height: 100%;
          justify-content: center;
          list-style: none;
          padding: 0 20px;
          transition: transform 200ms, text-shadow 200ms;

          &:active,
          &:hover,
          &.active {
            text-shadow: -5px 4px 2px rgba(0, 0, 0, 0.15);
            transform: translate(5px, -4px);
          }
  
          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      #hamburger {
        background: none;
        border: 0;
        cursor: pointer;
        outline: none;
        padding: 5px;
        position: fixed;
        right: 120px;
        z-index: 10;

        .line {
          background: $black;
          border-radius: 10px;
          height: 3px;
          margin: 7px 0;
          transition: all 200ms;
          width: 30px;

          &.open {
            background: white;

            &:nth-child(2) {
              opacity: 0;
              transform: translateX(-15px);
              width: 0;
            } 

            &:nth-child(1) {
              transform: translateY(10px) rotate(-45deg);
            }
        
            &:nth-child(3) {
              transform: translateY(-10px) rotate(-135deg);
            } 
          }
        }
      }

      #hamburger-menu {
        background: $blue;
        clip: rect(0, $hamburger-menu-width, 100vh, $hamburger-menu-width);
        height: 100vh;
        position: fixed;
        right: 0;
        top: 0;
        transition: clip 200ms;
        width: $hamburger-menu-width;
    
        &.open {
          clip: rect(0, $hamburger-menu-width, 100vh, 0);
        }

        &.closed {
          opacity: 0;
        }

        #nav-items {
          align-items: flex-end;
          color: white;
          flex-flow: column;
          height: 150px;
          margin-top: 200px;
          padding: 0 30px;
          position: relative;

          li {
            height: calc(200px / 4);
          }

          .line {
            background: white;
          }
        }
      }
      @media only screen and (max-width: 570px) {
        height: $navbar-height-mobile;

        .logo {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}
