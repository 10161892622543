@import './constants';

.footer {
  align-items: center;
  background: $light;
  display: flex;
  justify-content: center;
  margin-top: $section-vertical-margin * 2;
  padding: 30px 0;
  width: 100vw;

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: $content-max-width;
    width: $content-width;

    .left {
      .logo {
        height: 54px;
        position: absolute;
        width: 54px;
      }

      .content {
        margin-left: 64px;

        a {
          color: inherit;
          font-family: 'Bebas Neue', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          font-size: 35px !important;
          font-weight: 400;
          letter-spacing: 3px;
          text-decoration: none;
        }

        p {
          font-size: 14px;
          margin-top: -5px;
        }

        span {
          color: $red;
        }
      }
    }

    .right {
      display: flex;

      ul {
        font-size: 14px;
        list-style: none;
        margin-left: 50px;

        .top {
          cursor: inherit;
          font-weight: 600;
          margin-bottom: 8px;

          &:hover {
            text-decoration: none;
          }
        }

        button {
          background: none;
          border: 0;
          color: inherit;
          font: inherit;

          &:hover {
            text-decoration: underline;
          }
        }

        li {
          cursor: pointer;
          margin-top: 2px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @media only screen and (max-width: 650px) {
      flex-flow: column;

      
      .right {
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
        max-width: 235px;
        width: 100%;

        
        ul {
          margin-left: 0;

          .top {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
