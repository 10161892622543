@import './constants';


.faq-section {
  width: 100vw;
  align-items: center;
  //background: linear-gradient(#fff8e8, #fff 700px);
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  padding: 2em;
}
.faq-main {
  width: $content-width;
  max-width: $content-max-width;
  margin-left: 2rem;

  .column {
    margin-top: 20px;
    align-items: center;
  }

  .title {
    width: 100%;
    color: #333333;
  }

  .questions {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    

    a {
      color: $black;

      &:hover {
        text-decoration: underline;
      }
    }
    .answer {
      padding: 10px;
      font-family: Calibri;
      color: black;
    }

    .item {
      font-size: 14px;
      background: #fff;
      border: 2px solid $black;
      border-radius: 12px;
      width: 16em;
      font-family: 'Bebas Neue';
      color: $black;
      margin-bottom: 1.5rem;

      &.show {
        .expand-icon {
          .vertical {
            height: 2px;
          }
        }
      }

      button,
      .text {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        transition: border-left 200ms;
        white-space: nowrap;
        width: 100%;

        p {
          font-size: 14px;
          transition: transform 200ms, text-shadow 200ms;
        }

        .expand-icon {
          align-items: center;
          display: flex;
          height: 8px;
          justify-content: center;
          width: 8px;

          div {
            background: rgb(105, 105, 105);
            border-radius: 5px;
            box-shadow: -1px 1px 2px rgba($color: #333333, $alpha: 0.1);
          }

          .horizontal {
            height: 2px;
            width: 100%;
          }

          .vertical {
            height: 100%;
            margin-left: calc(-50% - 1px);
            transition: height 200ms;
            width: 2px;
          }
        }
      }

      button:hover {
        p {
          text-shadow: -3px 2px 4px rgba(0, 0, 0, 0.15);
          transform: translate(5px, -3px);
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    flex-flow: column;

    .right {
      margin-top: 20px;
      padding-left: 0;
    }
  }
}
