$black: #333;
$light: #f8f7f6;
$gray: #EAEBED;
$dark-blue: #006989;
$blue: #007090;
$light-blue: #A3BAC3;
$cyan: #01A7C2;
$red: #f03812;
$yellow: #fe8826;

$content-max-width: 850px;
$content-width: 100vw;
$section-vertical-margin: 50px;

$banner-height: 0;
$banner-bg-color: $blue;
$banner-hover-bg-color: #26888b;
$banner-color: white;

$navbar-height: 80px;
$navbar-height-mobile: 60px;
$hamburger-menu-width: 180px;

$red-gradient: linear-gradient(181deg, $red 60%, $blue 200%);
