@import './constants';

.prizes {
  a {
    color: $cyan;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  .prize-container {
    column-gap: 15px;
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 15px;

    .prize-item {
      .prize-item-imgcon {
        text-align: center;

        .prize-item-img {
          display: inline-block;
          max-height: 90px;
          text-align: center;
        }
      }

      .prize-item-content {
        display: inline-block;
        padding: 0 7px 7px;

        h3 {
          font-size: 1.5em;
          margin: 3px;
        }
      }
    }

    .center {
      text-align: center;
    }
  }

  @media only screen and (max-width: 570px) {
    .prize-container {
      grid-template-columns: auto;

      a {
        font-size: 1em;
      }

      .prize-item {
        .prize-item-imgcon {
          .prize-item-img {
            max-height: 60px;
          }
        }

        .prize-item-content {
          h3 {
            font-size: 1em;
          }
        }
      }
    }
  }
}

.prize-note {
  color: rgba($black, 0.75);
  font-size: 0.8em;
  margin-top: 20px;
}
