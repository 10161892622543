@import './constants';

h2 {
  color: #333333;
}
p {
  font-family: "Times New Roman", serif;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.live {
  .navbar {
    .navigation {
      .container {
        .logo-live {
          background-image: url('../images/scissor-logo.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 125%;
        }
        #hamburger {
          right: 120px;
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;

    .content {
      column-gap: 50px;
      flex-flow: row;
      align-items: center;
      

      .container {
        height: fit-content;
        align-items: flex-start;

        h1 {
          margin-left: 5px;
          font-size: 3em;
        }

        .countdown {
          column-gap: 15px;
          display: flex;
          margin: 20px 0;

          .time {
            width: 40px;

            h2 {
              text-align: center;
              font-family: "Times New Roman", Serif, serif;
              font-size: 2.2em;
            }
            p {
              text-align: center;
            }
          }

          .separator {
            font-size: 2rem;
          }
        }
      }
    }

    @media only screen and (max-width: 840px) {
      .content {
        column-gap: 30px;

        .container {
          h1 {
            font-size: 1.7em;
            font-family: "Times New Roman", serif;
          }

          .countdown {
            margin: 20px 0 10px;
          }
        }
      }
    }

    @media only screen and (max-width: 570px) {
      .content {
        column-gap: 10px;

        .container {
          h1 {
            font-size: 1.2em;
          }

          .countdown {
            column-gap: 10px;
            margin: 12px 0 8px;

          }
        }
      }
    }
  }

  .cta {
    background-color: $light-blue;
    .cta-box {
      //background-image: url('../images/triangle.svg');
      background-size: 50%;
      align-items: left;
      border-radius: 6px;
      box-sizing: border-box;
      display: flex;
      flex-flow: column;
      justify-content: center;
      max-width: $content-max-width;
      padding: 6.5em 4em 6.5em 4em;
      text-align: center;
      width: $content-width;
    }

    .cta-title {
      text-align: center;
      font-size: 4rem;
      border-bottom: solid $black 2px;
      margin: 0 10px 30px 10px;
    }
    .step {
      margin-left: 50px;
      text-align: left;
      column-gap: 10px;
      display: flex;
      margin-bottom: 18px;
      max-width: 600px;
      text-align: left;

      a {
        color: $blue;
        font-size: 1em;

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      h2 {
        font-size: 1.5rem;
        background: unset;
        background-clip: unset;
        color: $black;
        margin: 0;
        min-width: 20px;
        -webkit-text-fill-color: unset;
  
      }
    }
  }

  .live-stream {
    max-width: 350px;
    text-align: center;
    width: $content-width;

    a {
      color: white;
      font-weight: 800;
      height: 20px;
      text-decoration: none;
      transition: background 150ms;
      
      p {
        background: $cyan;
        border-radius: 4px;
        padding: 7px 14px;
        transition: transform 150ms;
        font-family: "Times New Roman", serif;
      }

      &:hover {
        background: #21797c;

        p {
          transform: translate(1.5px, -1px);
        }
      }
    }
  }

  .schedule {
    padding-bottom: 5px;
    display: block;

    h2 {
      margin-bottom: 0;
      border-bottom: 2px $black solid;
    }

    .container {
      column-gap: 30px;
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      margin-top: 0;
      padding-left: 0;

      h3 {
        margin-bottom: 5px;
        margin-top: 25px;
      }

      .item {
        &.completed {
          text-decoration: line-through;
        }
        
        &.ongoing {
          animation: text-pulse 0.5s infinite alternate ease-out;
          color: $cyan;
        }

        .text,
        button {
          max-width: 100%;
          
          p {
            display: flex;
            white-space: normal;
            word-wrap: break-all;
            
            span {
              display: block;
              text-align: left; 
            }

            strong {
              display: block;
              margin-right: 7px;
              min-width: fit-content;
              text-align: left;
            }
          }
        }
      }
    }

    .note {
      color: rgba($black, 0.75);
      float: right;
      font-size: 0.8em;
      margin-top: 20px;
    }

    @media only screen and (max-width: 570px) {
      .container {
        h3 {
          font-size: 0.825em;
          margin-top: 15px;
        }
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes text-pulse {
  100% {
    transform: translateX(5px);
  }
}

.faq {
  display: flex;

  position: relative;

  .left {
    max-width: 400px;

    a {
      color: $cyan;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .right {
    box-sizing: border-box;
    padding-left: 5vw;
    width: 100%;

    .answer {
      background: rgba($color: $light, $alpha: 0.8);
      padding: 8px 4px;
    }

    .item {
      font-size: 14px;

      &.show {
        .expand-icon {
          .vertical {
            height: 2px;
          }
        }
      }

      button,
      .text {
        align-items: center;
        border-bottom: 2px solid #f2f2f2;
        border-left: 0 solid rgba($color: $cyan, $alpha: 0.2);
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        transition: border-left 200ms;
        white-space: nowrap;
        width: 100%;

        p {
          transition: transform 200ms, text-shadow 200ms;
        }

        .expand-icon {
          align-items: center;
          display: flex;
          height: 8px;
          justify-content: center;
          width: 8px;

          div {
            background: rgb(105, 105, 105);
            border-radius: 5px;
            box-shadow: -1px 1px 2px rgba($color: #000, $alpha: 0.1);
          }

          .horizontal {
            height: 2px;
            width: 100%;
          }

          .vertical {
            height: 100%;
            margin-left: calc(-50% - 1px);
            transition: height 200ms;
            width: 2px;
          }
        }
      }

      button:hover {
        p {
          text-shadow: -3px 2px 4px rgba(0, 0, 0, 0.15);
          transform: translate(5px, -3px);
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    flex-flow: column;

    .right {
      margin-top: 20px;
      padding-left: 0;
    }
  }
}