@import './constants';

.sponsors {
  p { 
    a {
      color: #333333;
  
      &:hover {
        text-decoration: underline;
      }
    }
  }

  h2 {
    color: #333333;
  }

  .container {
    i {
        font-size: 1.25rem;
    }
    display: flex;

    .tier {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 60px;
      justify-content: center;
      margin-top: 20px;
      
      &.tier1 {
        img {
          max-width: 400px;
          max-height: 150px;
        }
      }

      &.tier2 {
        img {
          max-width: 300px;
          max-height: 100px;
        }
      }

      &.tier3 {
        img {
          max-width: 200px;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  &#partners {
    .container {
      justify-content: center;
    }
  }

  @media only screen and (max-width: 570px) {
    .container {
      .tier {
        &.tier1 {
          img {
            max-width: 250px;
          }
        }
  
        &.tier2 {
          img {
            max-width: 175px;
          }
        }
  
        &.tier3 {
          img {
            max-width: 130px;
          }
        }
      }
    }
  }
}