@import './constants';


.about {
  align-items: center;
  display: flex;
  justify-content: center;

  .box {
    //background-image: url('../images/triangle.svg');
    background-size: 50%;
    align-items: center;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    max-width: $content-max-width;
    padding: 5.1em 4em 5.1em 4em;
    text-align: center;
    width: $content-width;

    h3 {
      color: #333;
      font-family: newspaper, Serif, serif;
    }

    p {
      color: #333;
      font-family: "Times New Roman", Serif, serif;
      max-width: 520px;
    }
  }
}
