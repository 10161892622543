@import './constants';

.guides {
  h2 {
    color: $black;
  }
  
  .container {
    display: flex;
    flex-flow: column;

    .description {
      max-width: 550px;
      text-align: left;

      span {
        color: $red;
        font-style: italic;
      }
    }

    .tracks {
      display: flex;
      margin-top: 20px;
      position: relative;

      .track {
        border-radius: 8px;
        box-shadow: -2px 3px 4px 0 rgba($color: #000, $alpha: 0.1), inset 0 0 3px rgba($color: #000, $alpha: 0.1);
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        font-weight: 600;
        justify-items: center;
        padding: 15px 12px;
        transition: background 150ms;
        width: calc(100% / 3);

        &:hover {
          background: rgba($color: $light, $alpha: 0.8);

          .head,
          .body {
            transform: translate(2px, -2px);
          }
        }

        .head,
        .body {
          transition: transform 150ms;
        }

        .body {
          font-weight: 400;
          margin-top: 8px;
        }

        &:nth-child(2) {
          margin: 0 15px;
        }

        @media only screen and (max-width: 570px) {
          padding: 12px 8px;

          &:nth-child(2) {
            margin: 0 10px;
          }
        }
      }
    }
  }
}
